/* eslint-disable indent */
import * as cmk_figures from "cmk_figures";

// TODO: Use library functions from web/htdocs/js/modules/number_format.js
let NTOPNG_MIN_VISUAL_VALUE = 0.005;
export let ifid_dep = "ifid_dependent";
let table_class = cmk_figures.figure_registry.get_figure("table");

export class interface_table extends table_class {
    set_host_address(host_address) {
        this._host_address = host_address;
    }
    update_gui() {
        table_class.prototype.update_gui.call(this);
        this._div_selection.select("select").on("change", event => {
            this._change_post_url(d3.select(event.target));
            this.scheduler.force_update();
        });
    }
    _change_post_url(select) {
        let ifid = select.property("value");
        let host_body = this._host_address ? "&host_address=" + this._host_address : "";
        this.set_post_url_and_body("ajax_ntop_interface_quickstats.py?ifid=" + ifid + host_body);
        d3.selectAll("." + ifid_dep)
            .data()
            .forEach(o => o.set_ifid(ifid));
    }
}

export function bytes_to_volume(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Bytes";
    if (bytes > 0 && bytes < NTOPNG_MIN_VISUAL_VALUE)
        return "< " + NTOPNG_MIN_VISUAL_VALUE + " Bytes";
    var res = scale_value(bytes, sizes, 1024);
    return parseFloat(res[0]) + " " + res[1];
}

function scale_value(val, sizes, scale) {
    if (val == 0) return [0, sizes[0]];

    var i = parseInt(Math.floor(Math.log(val) / Math.log(scale)));
    if (i < 0 || isNaN(i)) {
        i = 0;
    } else if (i >= sizes.length) i = sizes.length - 1;

    return [Math.round((val / Math.pow(scale, i)) * 10) / 10, sizes[i]];
}

export function seconds_to_time(seconds) {
    if (seconds < 1) {
        return "< 1 sec";
    }

    let days = Math.floor(seconds / 86400);
    let hours = Math.floor(seconds / 3600 - days * 24);
    let minutes = Math.floor(seconds / 60 - days * 1440 - hours * 60);
    let sec = seconds % 60;
    let msg = "",
        msg_array = [];

    if (days > 0) {
        let years = Math.floor(days / 365);

        if (years > 0) {
            days = days % 365;

            msg = years + " year";
            if (years > 1) {
                msg += "s";
            }

            msg_array.push(msg);
            msg = "";
        }
        msg = days + " day";
        if (days > 1) {
            msg += "s";
        }
        msg_array.push(msg);
        msg = "";
    }

    if (hours > 0) {
        if (hours < 10) msg = "0";
        msg += hours + ":";
    }

    if (minutes < 10) msg += "0";
    msg += minutes + ":";

    if (sec < 10) msg += "0";

    msg += sec;
    msg_array.push(msg);
    return msg_array.join(", ");
}
